/*
@font-face {
    font-family: 'FontAwesome';
    src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0");
    src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format('embedded-opentype'), url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0") format('woff2'), url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0") format('woff'), url("../bower_components/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0") format('truetype'), url("../bower_components/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'themify';
    src: url("../bower_components/themify-icons/fonts/themify.eot");
    src: url("../bower_components/themify-icons/fonts/themify.eot?#iefix") format('embedded-opentype'),
        url("../bower_components/themify-icons/fonts/themify.woff") format('woff'),
        url("../bower_components/themify-icons/fonts/themify.ttf") format('truetype'),
        url("../bower_components/themify-icons/fonts/themify.svg") format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'et-line';
    src: url('../et-line-font/fonts/et-line.eot');
    src: url('../et-line-font/fonts/et-line.eot?#iefix') format('embedded-opentype'),
        url('../et-line-font/fonts/et-line.woff') format('woff'),
        url('../et-line-font/fonts/et-line.ttf') format('truetype'),
        url('../et-line-font/fonts/et-line.svg#et-line') format('svg');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans:300,300i,400,400i,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Droid+Serif:400,400i');
*/
/*
@font-face {
    font-family: 'themify';
    src: url("../../fonts/themify-icons/themify.eot");
    src: url("../../fonts/themify-icons/themify.eot?#iefix") format('embedded-opentype'),
         url("../../fonts/themify-icons/themify.woff") format('woff'),
         url("../../fonts/themify-icons/themify.ttf") format('truetype'),
         url("../../fonts/themify-icons/themify.svg") format('svg');
    font-weight: normal;
    font-style: normal;
}
*/
// 500,300
/* prompt-100 - latin */

/*
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 100;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-100.eot');
    src: local('Prompt Thin'), local('Prompt-Thin'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-100.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-100.woff2') format('woff2'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-100.woff') format('woff'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-100.ttf') format('truetype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-100.svg#Prompt') format('svg');
}
*/



/* prompt-200 - latin */
/*
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 200;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-200.eot');
    src: local('Prompt ExtraLight'), local('Prompt-ExtraLight'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-200.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-200.woff2') format('woff2'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-200.woff') format('woff'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-200.ttf') format('truetype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-200.svg#Prompt') format('svg');
}
*/

/* prompt-300 - latin */
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-300.eot');
    /* IE9 Compat Modes */
    src: local('Prompt Light'), local('Prompt-Light'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-300.svg#Prompt') format('svg');
    /* Legacy iOS */
}

/* prompt-regular - latin */
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Prompt'), local('Prompt-Regular'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-regular.svg#Prompt') format('svg');
    /* Legacy iOS */
}

/* prompt-500 - latin */
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-500.eot');
    /* IE9 Compat Modes */
    src: local('Prompt Medium'), local('Prompt-Medium'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-500.woff') format('woff'),
        /* Modern Browsers */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-500.svg#Prompt') format('svg');
    /* Legacy iOS */
}

/* prompt-600 - latin */
/*
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-600.eot');
    src: local('Prompt SemiBold'), local('Prompt-SemiBold'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-600.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-600.woff2') format('woff2'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-600.woff') format('woff'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-600.ttf') format('truetype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-600.svg#Prompt') format('svg');
}
*/

/* prompt-700 - latin */
/*
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-700.eot');
    src: local('Prompt Bold'), local('Prompt-Bold'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-700.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-700.woff2') format('woff2'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-700.woff') format('woff'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-700.ttf') format('truetype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-700.svg#Prompt') format('svg');
}
*/

/* prompt-800 - latin */
/*
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 800;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-800.eot');
    src: local('Prompt ExtraBold'), local('Prompt-ExtraBold'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-800.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-800.woff2') format('woff2'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-800.woff') format('woff'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-800.ttf') format('truetype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-800.svg#Prompt') format('svg');
}
*/
/* prompt-900 - latin */
/*
@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/prompt-v4-latin/prompt-v4-latin-900.eot');
    src: local('Prompt Black'), local('Prompt-Black'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-900.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-900.woff2') format('woff2'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-900.woff') format('woff'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-900.ttf') format('truetype'),
        url('../../fonts/prompt-v4-latin/prompt-v4-latin-900.svg#Prompt') format('svg');
}
*/